<router>
{
    name: 'Customers',
}
</router>
<template lang="pug">
v-container(fluid)
    v-row
        v-col(cols='8')
            DevopsDatabase

</template>
<script>

import DevopsDatabase from '@/components/devops/database';
export default {
    meta: {
        role: 'devopsdatabase'
    },
    components: {
        DevopsDatabase
    },
    data () {
        return {
        };
    },
    methods: {

    },
    computed: {

    },
    mounted () {
    }
};
</script>

<style scoped>
</style>
